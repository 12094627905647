import React from 'react';
import './Projects.css';

const projectDetails = {
  Airchains: {
    name: 'Airchains',
    guide: 'https://github.com/Sebatian1/Cosmos/tree/main/Airchain',
    explorer: 'https://explorer.sebatian.org/',
    logo: '/logos/airchains-logo.png'
  },
  Initia: {
    name: 'Initia',
    guide: 'https://github.com/Sebatian1/Cosmos/tree/main/Initia',
    explorer: 'https://scan.testnet.initia.xyz/initiation-2',
    logo: '/logos/initia-logo.png'
  },
  Elixir: {
    name: 'Elixir',
    guide: 'https://github.com/Sebatian1/Mainnet/tree/main/Elixir',
    explorer: 'https://www.elixir.xyz/network',
    logo: '/logos/elixir-logo.png'
  },
  '0g': {
    name: '0g',
    guide: 'https://github.com/Sebatian1/Cosmos/tree/main/0g',
    explorer: 'https://explorer.sebatian.org/',
    logo: '/logos/0g-logo.png'
  },
  Pactus: {
    name: 'Pactus',
    guide: 'https://github.com/Sebatian1/Mainnet/tree/main/Pactus',
    explorer: 'https://pacviewer.com',
    logo: '/logos/pactus-logo.png'
  },
  Tanssi: {
    name: 'Tanssi',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Tanssi',
    explorer: 'https://dancebox.subscan.io',
    logo: '/logos/tanssi-logo.png'
  },
  Nubit: {
    name: 'Nubit',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Nubit',
    explorer: 'https://explorer.nubit.org',
    logo: '/logos/nubit-logo.png'
  },
  Dill: {
    name: 'Dill',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Dill',
    explorer: 'https://andes.dill.xyz',
    logo: '/logos/dill-logo.png'
  },
   Pell: {
    name: 'Pell',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Pell',
    explorer: 'https://explorer.sebatian.org/',
    logo: '/logos/pell-logo.png'
  },

};

const colors = ['#e0e7ff', '#ffe0f0', '#e7ffe0', '#e0f7ff', '#f7e0ff', '#ffefe0', '#f0ffe0'];

const Projects = () => {
  return (
    <div className="projects-page">
      <h1 className="title">Our Projects</h1>
      <div className="projects">
        {Object.keys(projectDetails).map((key, index) => (
          <div 
            className="project-card" 
            key={key} 
            style={{ backgroundColor: colors[index % colors.length] }}
          >
            <img src={projectDetails[key].logo} alt={`${projectDetails[key].name} Logo`} className="project-logo" />
            <h2 className="project-name">{projectDetails[key].name}</h2>
            <div className="links">
              <a href={projectDetails[key].guide} target="_blank" rel="noopener noreferrer">Guide</a>
              <a href={projectDetails[key].explorer} target="_blank" rel="noopener noreferrer">Explorer</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
